var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Extended" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeExtended) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [_vm._v("You can extend your custom rule.")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "prism",
                { staticClass: "rounded ", attrs: { language: "javascript" } },
                [_vm._v(" " + _vm._s(_vm.codeCredit) + " ")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c(
                "prism",
                {
                  staticClass: "rounded mb-2",
                  attrs: { language: "javascript" }
                },
                [_vm._v(" " + _vm._s(_vm.codePositive) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Credit Card" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Credit Card",
                              rules: "required|credit-card"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Credit Card Number"
                                      },
                                      model: {
                                        value: _vm.cardNUm,
                                        callback: function($$v) {
                                          _vm.cardNUm = $$v
                                        },
                                        expression: "cardNUm"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Enter Only Positive Number" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Number",
                              rules: "required|positive"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Positive Number"
                                      },
                                      model: {
                                        value: _vm.posNum,
                                        callback: function($$v) {
                                          _vm.posNum = $$v
                                        },
                                        expression: "posNum"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { type: "submit", variant: "primary" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.validationForm($event)
                        }
                      }
                    },
                    [_vm._v(" Submit ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }