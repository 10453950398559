var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Validation Locale" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLocale) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          "VeeValidate has built-in localization support for validation messages. Localization is opt-in and is not configured by default."
        )
      ]),
      _c(
        "validation-observer",
        { ref: "form" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("validation-provider", {
                        attrs: { name: "email", rules: "required|email" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "email",
                                    state: errors.length > 0 ? false : null,
                                    placeholder: "Email"
                                  },
                                  model: {
                                    value: _vm.email,
                                    callback: function($$v) {
                                      _vm.email = $$v
                                    },
                                    expression: "email"
                                  }
                                }),
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0]))
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("validation-provider", {
                        attrs: { name: "password", rules: "required|min:6" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "password",
                                    state: errors.length > 0 ? false : null,
                                    placeholder: "Password"
                                  },
                                  model: {
                                    value: _vm.passwordValue,
                                    callback: function($$v) {
                                      _vm.passwordValue = $$v
                                    },
                                    expression: "passwordValue"
                                  }
                                }),
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0]))
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-button",
        { attrs: { variant: "primary" }, on: { click: _vm.switchLoc } },
        [_vm._v(" Switch Locale ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }