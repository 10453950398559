var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Field Name Placeholder" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePlaceholder) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          "vee-validate offers simple interpolation mechanism for returned messages, you can use the {_field_} placeholder in your string and it will be replaced with your field name automatically:"
        )
      ]),
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: { label: "Username", "label-for": "username" }
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Username", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "username",
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Username"
                                      },
                                      model: {
                                        value: _vm.username,
                                        callback: function($$v) {
                                          _vm.username = $$v
                                        },
                                        expression: "username"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: { label: "Password", "label-for": "password" }
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "Password", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "password",
                                        type: "password",
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Password"
                                      },
                                      model: {
                                        value: _vm.passValue,
                                        callback: function($$v) {
                                          _vm.passValue = $$v
                                        },
                                        expression: "passValue"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", type: "submit" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.validationForm($event)
                            }
                          }
                        },
                        [_vm._v(" Login ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }