var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Arguments Placeholders" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePlaceholderArgument) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          "vee-validate interpolation can parse placeholders that match the rule parameters, so to define such a message for the min rule you can use a {length} placeholder in the error message like this:"
        )
      ]),
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Password",
                            "label-for": "a-password"
                          }
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Password",
                              vid: "Password",
                              rules: "required|min:8|password"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "a-password",
                                        state: errors.length > 0 ? false : null,
                                        type: "password",
                                        placeholder: "Password"
                                      },
                                      model: {
                                        value: _vm.username,
                                        callback: function($$v) {
                                          _vm.username = $$v
                                        },
                                        expression: "username"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Confirm Password",
                            "label-for": "ac-password"
                          }
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "Confirm Password",
                              rules: "required|confirmed:Password"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "ac-password",
                                        state: errors.length > 0 ? false : null,
                                        type: "password",
                                        placeholder: "Confirm Password"
                                      },
                                      model: {
                                        value: _vm.passValue,
                                        callback: function($$v) {
                                          _vm.passValue = $$v
                                        },
                                        expression: "passValue"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", type: "submit" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.validationForm($event)
                            }
                          }
                        },
                        [_vm._v(" Reset ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }