var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Validation types" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeType) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [_vm._v("This field is required")]),
                          _c("validation-provider", {
                            attrs: { rules: "required", name: "First Name" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Fist Name"
                                      },
                                      model: {
                                        value: _vm.name,
                                        callback: function($$v) {
                                          _vm.name = $$v
                                        },
                                        expression: "name"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [_vm._v("Enter Number between 10 & 20")]),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|between:10,20",
                              name: "Number between"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder:
                                          "Enter Number between 10 & 20"
                                      },
                                      model: {
                                        value: _vm.numberRange,
                                        callback: function($$v) {
                                          _vm.numberRange = $$v
                                        },
                                        expression: "numberRange"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [_vm._v("Must only consist of numbers")]),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|integer",
                              name: "Number"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Enter Number Only"
                                      },
                                      model: {
                                        value: _vm.number,
                                        callback: function($$v) {
                                          _vm.number = $$v
                                        },
                                        expression: "number"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [
                            _vm._v(
                              "Must match the specified regular expression : ^([0-9]+)$ - numbers only"
                            )
                          ]),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|regex:^([0-9]+)$",
                              name: "Regex"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder:
                                          "Enter Specified regular expression"
                                      },
                                      model: {
                                        value: _vm.numberRegx,
                                        callback: function($$v) {
                                          _vm.numberRegx = $$v
                                        },
                                        expression: "numberRegx"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [_vm._v("Only alphabetic characters")]),
                          _c("validation-provider", {
                            attrs: { rules: "required|alpha", name: "Alpha" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Enter Character Only"
                                      },
                                      model: {
                                        value: _vm.Alphabetic,
                                        callback: function($$v) {
                                          _vm.Alphabetic = $$v
                                        },
                                        expression: "Alphabetic"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [
                            _vm._v(
                              "Length should not be less than the specified length : 3"
                            )
                          ]),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|min:3",
                              name: "Min Character"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder:
                                          "Enter minimum 3 Characters"
                                      },
                                      model: {
                                        value: _vm.digitValue,
                                        callback: function($$v) {
                                          _vm.digitValue = $$v
                                        },
                                        expression: "digitValue"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [_vm._v("Password Input Field")]),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|password",
                              name: "password",
                              vid: "password"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "password",
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Password"
                                      },
                                      model: {
                                        value: _vm.passwordValue,
                                        callback: function($$v) {
                                          _vm.passwordValue = $$v
                                        },
                                        expression: "passwordValue"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [
                            _vm._v(
                              "The digits field must be numeric and exactly contain 3 digits"
                            )
                          ]),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|digits:3",
                              name: "Numeric"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Enter Exactyly 3 digit"
                                      },
                                      model: {
                                        value: _vm.digitValue2,
                                        callback: function($$v) {
                                          _vm.digitValue2 = $$v
                                        },
                                        expression: "digitValue2"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [_vm._v("Repeat password must match")]),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|confirmed:password",
                              name: "Confirm Password"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "password",
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Repeat Password"
                                      },
                                      model: {
                                        value: _vm.passwordCon,
                                        callback: function($$v) {
                                          _vm.passwordCon = $$v
                                        },
                                        expression: "passwordCon"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [
                            _vm._v(
                              "Only alphabetic characters, numbers, dashes or underscores"
                            )
                          ]),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|alpha-dash",
                              name: "All Character"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder:
                                          "Enter Character, Numbers, Dash, Underscores"
                                      },
                                      model: {
                                        value: _vm.character,
                                        callback: function($$v) {
                                          _vm.character = $$v
                                        },
                                        expression: "character"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [_vm._v("Must be a valid email")]),
                          _c("validation-provider", {
                            attrs: { name: "Email", rules: "required|email" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Email"
                                      },
                                      model: {
                                        value: _vm.emailValue,
                                        callback: function($$v) {
                                          _vm.emailValue = $$v
                                        },
                                        expression: "emailValue"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("label", [_vm._v("Must be a valid url")]),
                          _c("validation-provider", {
                            attrs: { name: "URL", rules: "required|url" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        state: errors.length > 0 ? false : null,
                                        placeholder: "Enter Valid URL"
                                      },
                                      model: {
                                        value: _vm.URL,
                                        callback: function($$v) {
                                          _vm.URL = $$v
                                        },
                                        expression: "URL"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", type: "submit" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.validationForm($event)
                            }
                          }
                        },
                        [_vm._v(" Submit ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }